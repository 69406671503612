



















.el-select{
  .vue-treeselect {
    &__control {
      height: 40px;
      background: #FFF;
    }
    &__placeholder, &__single-value{
      line-height: 40px;
    }
    &__menu-container{
      min-width: 300px;
      label{
        height: 34px;
      }
    }
  }
  &.vue-treeselect--focused{
    .vue-treeselect__control{
      background: #FFF;
    }
  }
}

.el-select-group__title {
  padding-right: 20px;
}

.poste-select {
  .el-select-group__title {
    font-weight: 800;
    color: #000;
    padding-left: 5px;
  }
}
